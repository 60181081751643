import * as ACTION_TYPES from './Types';

const initialState = {
  crms: {},
  crm: {},
  message: '',
  error: null,
}

export const crmReducer = (state = initialState, action) => {
  switch (action.type) {

    case ACTION_TYPES.GET_CONTACTUS_SUCCESS:
    case ACTION_TYPES.UPDATE_SUCCESS:
      return {
        ...state,
        crms: action.payload.data,
        message: action.payload.message,
      }

    case ACTION_TYPES.GET_CONTACTUS_DETAILS_SUCCESS:
      return {
        ...state,
        crm: action.payload.data,
        message: action.payload.message,
      }

    case ACTION_TYPES.REPLY_REQ_SUCCESS:
    case ACTION_TYPES.GET_CONTACTUS_DETAILS_ERROR:
    case ACTION_TYPES.REPLY_REQ_ERROR:
    case ACTION_TYPES.GET_CONTACTUS_ERROR:
    case ACTION_TYPES.UPDATE_ERROR:
      return {
        ...state,
        message: action.payload.message,
      }
    default:
      return state;
  }
}
