export const GET_STACK_SUCCESS = "GET_STACK_SUCCESS";
export const GET_STACK_ERROR = "GET_STACK_ERROR";

export const GET_INTEREST_SUCCESS = "GET_INTEREST_SUCCESS";
export const GET_INTEREST_ERROR = "GET_INTEREST_ERROR";

export const UPLOAD_STACK_SUCCESS = "UPLOAD_STACK_SUCCESS";
export const UPLOAD_STACK_ERROR = "UPLOAD_STACK_ERROR";

export const UPDATECATEGORY_SUCCESS = "UPDATECATEGORY_SUCCESS";
export const UPDATECATEGORY_ERROR = "UPDATECATEGORY_ERROR";

export const UPDATE_URL_SUCCESS = "UPDATE_URL_SUCCESS";
export const UPDATE_URL_ERROR = "UPDATE_URL_ERROR";

