export const GETCATEGORY_SUCCESS = "GETCATEGORY_SUCCESS";
export const GETCATEGORY_ERROR = "GETCATEGORY_ERROR";

export const GET_INTEREST_SUCCESS = "GET_INTEREST_SUCCESS";
export const GET_INTEREST_ERROR = "GET_INTEREST_ERROR";

export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

export const ADDCATEGORY_SUCCESS = "ADDCATEGORY_SUCCESS";
export const ADDCATEGORY_ERROR = "ADDCATEGORY_ERROR";

export const UPDATECATEGORY_SUCCESS = "UPDATECATEGORY_SUCCESS";
export const UPDATECATEGORY_ERROR = "UPDATECATEGORY_ERROR";

export const CHANGEACTION_SUCCESS = "CHANGEACTION_SUCCESS";
export const CHANGEACTION_ERROR = "CHANGEACTION_ERROR";

