import * as ACTION_TYPES from './Types';

const initialState = {
  stackCategories: {},
  interests: {},
  users: {},
  message: '',
  error: null,
}

export const stackCategoryReducer = (state = initialState, action) => {
  switch (action.type) {

    case ACTION_TYPES.GETCATEGORY_SUCCESS:
      return {
        ...state,
        stackCategories: action.payload.data,
        message: action.payload.message,
      }

    case ACTION_TYPES.GETCATEGORY_ERROR:
      return {
        ...state,
        message: action.payload.message,
      }
    case ACTION_TYPES.GET_INTEREST_SUCCESS:
      return {
        ...state,
        interests: action.payload.data,
        message: action.payload.message,
      }
    case ACTION_TYPES.GET_USER_SUCCESS:
      return {
        ...state,
        users: action.payload.data,
        message: action.payload.message,
      }
    case ACTION_TYPES.GET_INTEREST_ERROR:
    case ACTION_TYPES.GET_USER_ERROR:

      return {
        ...state,
        message: action.payload.message,
      }
    case ACTION_TYPES.ADDCATEGORY_SUCCESS:
    case ACTION_TYPES.UPDATECATEGORY_SUCCESS:
      return {
        ...state,
        stackCategories: action.payload.data,
        message: action.payload.message,
      }
    case ACTION_TYPES.CHANGEACTION_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
      }
    case ACTION_TYPES.ADDCATEGORY_ERROR:
    case ACTION_TYPES.UPDATECATEGORY_ERROR:
    case ACTION_TYPES.CHANGEACTION_ERROR:
      return {
        ...state,
        message: action.payload.message,
      }

    default:
      return state;
  }
}
