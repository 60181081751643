import * as ACTION_TYPES from './Types';

const initialState = {
  likestacks: {},
  likestackDetails:{},
  message: '',
  error: null,
}

export const likestackReducer = (state = initialState, action) => {
  switch (action.type) {

    case ACTION_TYPES.GET_LIKESTACK_SUCCESS:
      return {
        ...state,
        likestacks: action.payload.data,
        message: action.payload.message,
      }

    case ACTION_TYPES.VIEW_LIKESTACK_SUCCESS:
      return {
        ...state,
        likestackDetails: action.payload.data,
        message: action.payload.message,
      }

    case ACTION_TYPES.GET_LIKESTACK_ERROR:
    case ACTION_TYPES.VIEW_LIKESTACK_ERROR:
    case ACTION_TYPES.DELETE_LIKESTACK_SUCCESS:
    case ACTION_TYPES.DELETE_LIKESTACK_ERROR:
      return {
        ...state,
        message: action.payload.message,
      }

    default:
      return state;
  }
}
