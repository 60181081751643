import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form' // redux-form reducer 
import { loginReducer } from './login/Reducer'
import {changeState} from './common/reducer'
import { dashboardReducer } from './Dashboard/Reducer'

import { customerReducer } from './CustomerManagemet/Reducer'
import { stackCategoryReducer } from './StackCategoryManagement/Reducer'
import { cmsReducer } from './Cms/Reducer'
import { likestackReducer } from './LikestackManagement/Reducer'
import { stackReducer } from './StackManagement/Reducer'
import { crmReducer } from './Crm/Reducer'

export const rootReducer = combineReducers({
  form: formReducer,
  changeState : changeState,
  login : loginReducer,
  dashboard: dashboardReducer,

  customer:customerReducer,
  stackcategory:stackCategoryReducer,
  cms:cmsReducer,
  likestack:likestackReducer,
  stack:stackReducer,
  crm:crmReducer
});
