export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

export const VIEW_USER_SUCCESS = "VIEW_USER_SUCCESS";
export const VIEW_USER_ERROR = "VIEW_USER_ERROR";

export const CHANGEACTION_SUCCESS = "CHANGEACTION_SUCCESS";
export const CHANGEACTION_ERROR = "CHANGEACTION_ERROR";

export const GET_INTEREST_SUCCESS = "GET_INTEREST_SUCCESS";
export const GET_INTEREST_ERROR = "GET_INTEREST_ERROR";

export const GET_FRIEND_SUCCESS = "GET_FRIEND_SUCCESS";
export const GET_FRIEND_ERROR = "GET_FRIEND_ERROR";
