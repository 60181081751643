import * as ACTION_TYPES from './Types';

const initialState = {
  customers: {},
  viewUser: {},
  userInterest: {},
  friends: {},
  message: '',
  error: null,
}

export const customerReducer = (state = initialState, action) => {
  switch (action.type) {



    case ACTION_TYPES.GET_USER_SUCCESS:
      return {
        ...state,
        customers: action.payload.data,
        message: action.payload.message,
      }

    case ACTION_TYPES.GET_USER_ERROR:
      return {
        ...state,
        message: action.payload.message,
      }  
    case ACTION_TYPES.CHANGEACTION_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
      }
    case ACTION_TYPES.VIEW_USER_SUCCESS:
      return {
        ...state,
        viewUser: action.payload.data,
        message: action.payload.message,
      }

    case ACTION_TYPES.VIEW_USER_ERROR:
    case ACTION_TYPES.CHANGEACTION_ERROR:
      return {
        ...state,
        message: action.payload.message,
      }
    case ACTION_TYPES.GET_INTEREST_SUCCESS:
      return {
        ...state,
        userInterest: action.payload.data,
        message: action.payload.message,
      }

    case ACTION_TYPES.GET_INTEREST_ERROR:
    case ACTION_TYPES.GET_FRIEND_ERROR:
      return {
        ...state,
        message: action.payload.message,
      }
    case ACTION_TYPES.GET_FRIEND_SUCCESS:
      return {
        ...state,
        friends: action.payload.data,
        message: action.payload.message,
      }
    default:
      return state;
  }
}
