export const GET_CONTACTUS_SUCCESS = "GET_CONTACTUS_SUCCESS";
export const GET_CONTACTUS_ERROR = "GET_CONTACTUS_ERROR";

export const GET_CONTACTUS_DETAILS_SUCCESS = "GET_CONTACTUS_DETAILS_SUCCESS";
export const GET_CONTACTUS_DETAILS_ERROR = "GET_CONTACTUS_DETAILS_ERROR"; 

export const UPDATE_SUCCESS = "UPDATE_SUCCESS";
export const UPDATE_ERROR = "UPDATE_ERROR";

export const REPLY_REQ_SUCCESS = "REPLY_REQ_SUCCESS";
export const REPLY_REQ_ERROR = "REPLY_REQ_ERROR";