import * as ACTION_TYPES from './Types';

const initialState = {
  cms: {},
  faq: {},
  message: '',
  error: null,
}

export const cmsReducer = (state = initialState, action) => {
  switch (action.type) {

    case ACTION_TYPES.GET_CMS_SUCCESS:
    case ACTION_TYPES.UPDATE_CMS_SUCCESS:
      return {
        ...state,
        cms: action.payload.data,
        message: action.payload.message,
      }
    case ACTION_TYPES.GET_CMS_ERROR:
    case ACTION_TYPES.UPDATE_CMS_ERROR:
      return {
        ...state,
        message: action.payload.message,
      }

    case ACTION_TYPES.GET_FAQ_SUCCESS:
    case ACTION_TYPES.UPDATE_FAQ_SUCCESS:
      return {
        ...state,
        faq: action.payload.data,
        message: action.payload.message,
      }
      
    case ACTION_TYPES.ADD_FAQ_SUCCESS:
    case ACTION_TYPES.GET_FAQ_ERROR:
    case ACTION_TYPES.ADD_FAQ_ERROR:
    case ACTION_TYPES.UPDATE_FAQ_ERROR:
    case ACTION_TYPES.DELETE_FAQ_SUCCESS:
    case ACTION_TYPES.DELETE_FAQ_ERROR:

      return {
        ...state,
        message: action.payload.message,
      }
    
    default:
      return state;
  }
}
