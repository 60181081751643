import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './scss/style.scss';
import 'react-toastify/dist/ReactToastify.css';
import store from './Redux/store'
import checkAuth from './Authentication/Auth'
import * as ACTIONS from './Redux/login/Action'
import { AUTHENTICATE_FAILED } from './Redux/login/Types';
import "./config/axiosConfig";
import 'antd/dist/antd.css';

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
// Pages
const Login = React.lazy(() => import('./views/pages/Login'));
const ForgotPassword = React.lazy(() => import('./views/pages/ForgotPassword'));
const Share = React.lazy(() => import('./views/pages/Share'));

const token = JSON.parse(localStorage.getItem("authToken"));
const loggedInUser = JSON.parse(localStorage.getItem("user"));

if (token && loggedInUser) {
  store.dispatch(ACTIONS.authenticate(loggedInUser));
} else {
  store.dispatch({ type: AUTHENTICATE_FAILED });
}

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

class App extends Component {
  render() {
    return (
      <HashRouter basename={process.env.PUBLIC_URL}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <React.Suspense fallback={loading}>
          <Switch >
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route exact path="/forgotpassword" name="Forgot Password" render={props => <ForgotPassword {...props} />} />
            <Route exact path="/share-likestack" name="Share likestak" render={props => <Share {...props} />} />
            <Route path="/" name="Home" component={checkAuth(TheLayout)} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;