export const GET_CMS_SUCCESS = "GET_CMS_SUCCESS";
export const GET_CMS_ERROR = "GET_CMS_ERROR";

export const UPDATE_CMS_SUCCESS = "UPDATE_CMS_SUCCESS";
export const UPDATE_CMS_ERROR = "UPDATE_CMS_ERROR";

export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
export const GET_FAQ_ERROR = "GET_FAQ_ERROR"; 

export const ADD_FAQ_SUCCESS = "ADD_FAQ_SUCCESS";
export const ADD_FAQ_ERROR = "ADD_FAQ_ERROR";

export const UPDATE_FAQ_SUCCESS = "UPDATE_FAQ_SUCCESS";
export const UPDATE_FAQ_ERROR = "UPDATE_FAQ_ERROR";

export const DELETE_FAQ_SUCCESS = "DELETE_FAQ_SUCCESS";
export const DELETE_FAQ_ERROR = "DELETE_FAQ_ERROR";