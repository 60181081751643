import * as ACTION_TYPES from './Types';

const initialState = {
  userData: {},
  isLoggedIn: false,
  passwordChange: false,
  message: '',
  error: null,
}

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {

    case ACTION_TYPES.LOGIN_SUCCESS:
      //localStorage.setItem('authToken',   JSON.stringify( "Bearer " +action.payload.data.token));
      localStorage.setItem('authToken', JSON.stringify(action.payload.data.accessToken));
      localStorage.setItem('user', JSON.stringify(action.payload.data.email));
      return {
        ...state,
        userData: action.payload.data,
        message: action.payload.message,
        isLoggedIn: true,
        error: null
      }

    case ACTION_TYPES.LOGIN_ERROR:
      return {
        ...state,
        message: action.payload.data.message,
        error: action.payload.data
      }

    case ACTION_TYPES.AUTHENTICATE_USER:
      return {
        ...state,
        isLoggedIn: true,
        userData: action.payload,
        error: null
      }
    case ACTION_TYPES.LOGOUT:
    case ACTION_TYPES.AUTHENTICATE_FAILED:
      localStorage.removeItem("authToken");
      localStorage.removeItem("user");
      return {
        ...state,
        isLoggedIn: false,
        message: '',
        userData: {}
      }
    case ACTION_TYPES.FORGOT_PASSWORD_SUCCESS:
    case ACTION_TYPES.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        message: action.payload.message
      }
    case ACTION_TYPES.CHANGE_PASSWORD_SUCCESS:
    case ACTION_TYPES.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        message: action.payload.message
      }
      
    default:
      return state;
  }
}
