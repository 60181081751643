import * as ACTION_TYPES from './Types';

const initialState = {
  categoryList: {},
  dashboardData: {},
  message: '',
  error: null,
}

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {

    case ACTION_TYPES.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryList: action.payload.data,
        message: action.payload.message,

      }
    case ACTION_TYPES.DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboardData: action.payload.data,
        message: action.payload.message,
      }
    case ACTION_TYPES.GET_CATEGORY_ERROR:
    case ACTION_TYPES.DASHBOARD_ERROR:
      return {
        ...state,
        message: action.payload.message,
      }

    default:
      return state;
  }
}
