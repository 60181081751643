import * as ACTION_TYPES from './Types';

const initialState = {
  stacks:{},
  interests:{},
  message: '',
  error: null,
}

export const stackReducer = (state = initialState, action) => {
  switch (action.type) {

    case ACTION_TYPES.GET_STACK_SUCCESS:  
      return {
          ...state,
          stacks:action.payload.data,
          message: action.payload.message,
      }

    case ACTION_TYPES.GET_STACK_ERROR:    
      return {
        ...state,
        message: action.payload.message,
      } 
    
    case ACTION_TYPES.UPLOAD_STACK_SUCCESS:
   case ACTION_TYPES.UPDATECATEGORY_SUCCESS:  
   case ACTION_TYPES.UPDATE_URL_SUCCESS:  

      return {
          ...state,
          message: action.payload.message,
      }

    case ACTION_TYPES.UPLOAD_STACK_ERROR:
    case ACTION_TYPES.UPDATECATEGORY_ERROR:
    case ACTION_TYPES.UPDATE_URL_ERROR:   
      return {
        ...state,
        message: action.payload.message,
      }               
        
    default:
      return state;
  }
}
